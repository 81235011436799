import { navigate } from 'gatsby'
import { useState } from 'react'
import useCrudFreeSample from '../../../shared/hooks/useCrudFreeSample'
import SamplesService from '../../../shared/services/samples'
import SessionService from '../../../shared/services/session'
import { localStorage } from '../../../shared/state/utils/storage'
import ProfileService from '../../../shared/services/profile'
import AdviserService from '../../../shared/services/adviser'
import useLocaleMode from '../../../shared/hooks/useLocaleMode'
import useCoverageState from '../../../shared/hooks/useCoverageState'
import useSessionState from '../../../shared/hooks/useSessionState'

const haveMainDirection = SessionService.haveMainDirection()
const haveSecondDirection = SessionService.haveSecondDirection()
export default function useConfirmationSample(_country, _products) {
	const country = _country
	const [validateCoverage] = useCoverageState([])
	const [samples, _removeSample, response] = useCrudFreeSample()
	const { locale } = useLocaleMode()
	const [department, setDepartment] = useState(null)
	const [city, setCity] = useState(null)
	const [address, setAddress] = useState(null)
	const [kindOfStreet, setKindOfStreet] = useState('Número Calle')
	const [firstNumberValue, setFirstNumberValue] = useState('# ')
	const [secondNumberValue, setSecondNumberValue] = useState('- ')
	const [numberValue, setNumberValue] = useState('')
	const [neighborhood, setNeighborhood] = useState('')
	const [block, setBlock] = useState('')
	const [aditionalInformation, setAditionalInformation] = useState('')
	const [addressDetail, setAddressDetail] = useState(null)
	const isConfirmValid = department && city && address
	const [loadedDirection, setLoadedDirection] = useState(false)
	const [loadedOtherDirection, setLoadedOtherDirection] = useState(false)
	const [isSampleSend, setIsSampleSend] = useState(false)
	const [awaitSamples, setAwaitSamples] = useState(false)
	const [newCorrectSamples, setNewCorrectSamples] = useState([])
	const [newWrongSamples, setNewWrongSamples] = useState([])
	const [samplesErrorModal, setSamplesErrorModal] = useState(false)
	const [sampleError, setSampleError] = useState(false)
	const [sampleErrorMessage, setSampleErrorMessage] = useState('')
	const { actualStorage } = useSessionState()

	/* Estados de los campos de Argentina */
	const [addressARG, setAddressARG] = useState("")
	const [heightARG, setHeightARG] = useState("")
	const [floorARG, setFloorARG] = useState("")
	const [departmentARG, setDepartmentARG] = useState("")
	const [zipCodeARG, setZipCodeARG] = useState("")
	const [aditionalInformationARG, setAditionalInformationARG] = useState("")

	const loadDirection = () => {
		const userData = SessionService.getUserData()
		if (userData) {
			locale === 'es-CO' ?
			actionDirecction(
				userData.mainCity,
				userData.mainAddress || '',
				userData.mainAddressDetail || '',
				userData.address ? userData.address.tipoCalle : '',
				userData.address ? userData.address.numeroTipo : '',
				userData.address ? userData.address.numero : '',
				userData.address ? userData.address.numeroSecundario : '',
				userData.addressDetail ? userData.addressDetail.barrio : '',
				userData.addressDetail ? userData.addressDetail.detalle : '',
				userData.addressDetail ? userData.addressDetail.referencia : ''
			) : locale === 'es-AR'?
				actionDirecctionARG(
					userData.mainCity,
					userData.mainAddress || '',
					userData.mainAddressDetail || '',
					userData.address ? userData.address.tipoCalle : '',
					userData.address ? userData.address.numeroTipo : '',
					userData.address ? userData.address.numero === "null" ? 'Sin piso' : userData.address.numero : '',
					userData.address ? userData.address.numeroSecundario === "null" ? 'Sin departamento' : userData.address.numeroSecundario : '',
					userData.addressDetail ? userData.addressDetail.barrio : '',
					userData.addressDetail ? userData.addressDetail.detalle === "null" ? '' : userData.addressDetail.detalle : '',
				)
				:
				actionDirecction(
					userData.mainCity,
					userData.mainAddress,
					userData.mainAddressDetail
				)
			} else {
			setLoadedDirection(true)
		}
	}
	const actionDirecction = (codeCity, address, mainAddressDetail, kindOfStreet, numberValue, firstNumberValue , secondNumberValue, neighborhood, block, aditionalInformation='') => {
		if (locale !== 'es-PR' && locale !== 'es-CO') {
			const cityFind = country.ciudades.find(
				city => city.codigo === codeCity.toString()
			)
			if (cityFind) {
				const depFind = country.departamentos.find(
					dep => dep.codigo === cityFind.codigoDepartamento
				)
				setDepartment(depFind.codigo)
				setCity(cityFind.codigo)
				setAddress(address)
				setAddressDetail(mainAddressDetail || '')
				setLoadedDirection(true)
				setLoadedOtherDirection(false)
			}
		} else if(locale === 'es-PR'){
			const villageFind = country.departamentos.find(
				village => codeCity.toString() === village.codigo
			)
			if (villageFind) {
				setDepartment(villageFind.codigo)
				setCity(null)
				setAddress(address)
				setAddressDetail(mainAddressDetail || '')
				setLoadedDirection(true)
				setLoadedOtherDirection(false)
			}
		}else{
			const cityFind = country.ciudades.find(
				city => city.codigo === codeCity.toString()
			)
			if (cityFind) {
				const depFind = country.departamentos.find(
					dep => dep.codigo === cityFind.codigoDepartamento
				)
				setDepartment(depFind.codigo)
				setCity(cityFind.codigo)
				setKindOfStreet(kindOfStreet)
				setNumberValue(numberValue)
				setFirstNumberValue(firstNumberValue)
				setSecondNumberValue(secondNumberValue)
				setNeighborhood(neighborhood)
				setBlock(block)
				setAditionalInformation(aditionalInformation)
				setAddress(address)
				setAddressDetail(mainAddressDetail || '')
				setLoadedDirection(true)
				setLoadedOtherDirection(false)
			}
		}
	}

	const actionDirecctionARG = (codeCity, address, mainAddressDetail, kindOfStreet, numberValue, firstNumberValue, secondNumberValue, neighborhood, aditionalInformation) => {
		const cityFind = country.ciudades.find(
			city => city.codigo === codeCity.toString()
		)
		
		if (cityFind) {
			const depFind = country.departamentos.find(
				dep => dep.codigo === cityFind.codigoDepartamento
			)

			setDepartment(depFind.codigo)
			setCity(cityFind.codigo)
			setAddressARG(kindOfStreet)
			setHeightARG(numberValue)
			setFloorARG(firstNumberValue)
			setDepartmentARG(secondNumberValue)
			setZipCodeARG(neighborhood)
			setAditionalInformationARG(aditionalInformation)
			setAddress(address)
			setAddressDetail(mainAddressDetail || '')
			setLoadedDirection(true)
			setLoadedOtherDirection(false)
		}
	}

	const changeDirection = () => {
		const userData = SessionService.getUserData()
		locale === 'es-CO' ? actionDirecction(
			userData.secondCity,
			userData.secondAddress,
			userData.secondAddressDetail,
			userData.secondNewAddress ? userData.secondNewAddress.tipoCalle : '',
			userData.secondNewAddress ? userData.secondNewAddress.numeroTipo : '',
			userData.secondNewAddress ? userData.secondNewAddress.numero : '# ',
			userData.secondNewAddress ? userData.secondNewAddress.numeroSecundario : '- ',
			userData.secondNewAddressDetail ? userData.secondNewAddressDetail.barrio : '',
			userData.secondNewAddressDetail ? userData.secondNewAddressDetail.detalle : '',
			userData.secondNewAddressDetail ? userData.secondNewAddressDetail.referencia : ''
			) 
			: locale === 'es-AR'?
			actionDirecctionARG(
				userData.secondCity,
				userData.secondAddress,
				userData.secondAddressDetail,
				userData.secondNewAddress ? userData.secondNewAddress.tipoCalle : '',
				userData.secondNewAddress ? userData.secondNewAddress.numeroTipo : '',
				userData.secondNewAddress ? userData.secondNewAddress.numero === "null" ? 'Sin piso' : userData.secondNewAddress.numero : '',
				userData.secondNewAddress ? userData.secondNewAddress.numeroSecundario === "null" ? 'Sin departamento' : userData.secondNewAddress.numeroSecundario : '',
				userData.secondNewAddressDetail ? userData.secondNewAddressDetail.barrio : '',
				userData.secondNewAddressDetail ? userData.secondNewAddressDetail.detalle === "null" ? '' : userData.secondNewAddressDetail.detalle : '',
			) 
			: 
			actionDirecction(
				userData.secondCity,
				userData.secondAddress,
				userData.secondAddressDetail,
			)
		setLoadedOtherDirection(true)
	}
	const resetDirectons = () => {
		setDepartment(null)
		setCity(null)
		setAddress('')
		setAddressDetail('')
		setKindOfStreet('')
		setFirstNumberValue('# ')
		setSecondNumberValue('- ')
		setNumberValue('')
		setNeighborhood('')
		setBlock('')
		setAditionalInformation('')
		setAddressARG('')
		setHeightARG('')
		setFloorARG('')
		setDepartmentARG('')
		setZipCodeARG('')
		setAditionalInformationARG('')
	}
	const sendSamples = () => {
		setAwaitSamples(true)
		const userData = SessionService.getUserData()
		const json = loadedOtherDirection
			? {
					mainAddress: address,
					mainAddressDetail: addressDetail,
					email: userData.email,
					secondAddress: userData.mainAddress,
					secondAddressDetail: userData.mainAddressDetail,
					mainCity: city,
					samples: true,
					mainCountryState: department,
					mainCountry: userData.mainCountry
			  }
			: {
					email: userData.email,
					mainAddress: address,
					mainAddressDetail: addressDetail,
					mainCity: city,
					mainCountryState: department,
					mainCountry: userData.mainCountry,
					samples: true,
			  }
		ProfileService.updateProfile(json,
			res => {
					if (res.data.status === 200) {
						const updatedData = JSON.stringify(res.data.data)
						actualStorage === 0
							? localStorage.setItem('userData', updatedData)
							: sessionStorage.setItem('userData', updatedData)
				}
				actionSend()
			},
			  error => {
			setSampleError(true)
			setSampleErrorMessage('Inténtalo de nuevo más tarde.')
			console.log('error', error)
		})
	}

	const actionSend = response => {
		const samples = SessionService.getSamples()
		const formatSamples =
			samples &&
			samples.map(sample => {
				return {
					id: sample.id,
					size: sample.size,
					destiny: sample.destiny,
					name: sample.name,
				}
			})
		SamplesService.sendSamples(
			formatSamples,
			samples[0].img,
			sucess => {
				if (sucess.data.status === 200) {
					SamplesService.deleteUsersCartForgotten(error => {
						console.log('error', error)
					})
					localStorage.removeItem('userAdded')
					localStorage.removeItem('samples')
					setIsSampleSend(true)
					setAwaitSamples(false)
					try {
						const currentProduct = _products.filter(
							product => product.codigoDeProducto === samples[0].id
						)[0]
						const productCategory =
							currentProduct.categorias[0].slug === 'mujeres'
								? 'Mujer'
								: currentProduct.categorias[0].slug === 'hombres'
								? 'Hombre'
								: 'Unisex'
						const productIncontinence =
							currentProduct.nivelIncontinencia &&
							currentProduct.nivelIncontinencia.content[0].content
								.map(function(elem) {
									return elem.value
								})
								.join('')

						window.dataLayer.push({
							event: 'transactionID',
							id: sucess.data.data.sampleId,
							category: 'Ecommerce',
							action: 'Purchase',
							'dimension 5': 'Muestra gratis',
							'product name': currentProduct.nombreProducto,
							'product id': currentProduct.nombreProducto,
							'product brand': 'TENA',
							'product category': productCategory,
							'product variant': productIncontinence,
							'dimension 6': '',
							'dimension 7': samples[0].size,
						})
					} catch (e) {
						console.log('dataLayer Error', e)
					}
				} else {
					setSampleError(true)
					setSampleErrorMessage(
						sucess.data.errorCode === 'DEM080' ||  sucess.data.errorCode === 'DEM079'
							? locale === 'es-AR'
							 ? 'Ya has solicitado una muestra en los últimos 12 meses'
							 	: sucess.data.userMessage
							: 'Inténtalo de nuevo más tarde.'
					)
					setAwaitSamples(false)
				}
			},
			error => {
				console.log('error', error)
				setSampleError(true)
				setSampleErrorMessage('Inténtalo de nuevo más tarde.')
				setAwaitSamples(false)
			}
		)
	}
	// adviser service
	const sendSamplesAdviser = () => {
		setAwaitSamples(true)
		const userData = SessionService.getUserData()
		const json = loadedOtherDirection
			? {
					mainAddress: address,
					mainAddressDetail: addressDetail,
					email: userData.email,
					secondAddress: userData.mainAddress,
					secondAddressDetail: userData.mainAddressDetail,
					mainCountryState: department,
					mainCountry: userData.mainCountry,
					mainCity: city,
			  }
			: {
					mainAddress: address,
					mainAddressDetail: addressDetail,
					mainCountryState: department,
					mainCountry: userData.mainCountry,
					mainCity: city,
					email: userData.email,
			  }
		json.samples = true
		AdviserService.updateProfile(json, actionSendAdviser, error => {
			setSampleError(true)
			setSampleErrorMessage('Inténtalo de nuevo más tarde.')
			console.log('error', error)
		})
	}
	const actionSendAdviser = response => {
		const samples = SessionService.getSamples()
		const formatSamples =
			samples &&
			samples.map(sample => {
				return {
					id: sample.id,
					size: sample.size,
					destiny: sample.destiny,
					name: sample.name,
				}
			})
		AdviserService.sendSamples(
			formatSamples,
			samples[0].img,
			sucess => {
				if (sucess.data.status === 200) {
					localStorage.removeItem('samples')
					setIsSampleSend(true)
					setAwaitSamples(false)
				} else {
					setSampleError(true)
					setSampleErrorMessage(
						sucess.data.errorCode === 'DEM080' ||  sucess.data.errorCode === 'DEM079'
							? locale  === 'es-AR'
								? 'Ya has solicitado una muestra en los últimos 12 meses.'
							  	: sucess.data.userMessage
							: 'Inténtalo de nuevo más tarde.'
					)
					setAwaitSamples(false)
				}
			},
			error => {
				console.log('error', error)
				setSampleError(true)
				setSampleErrorMessage('Inténtalo de nuevo más tarde.')
				setAwaitSamples(false)
			}
		)
	}
	// end adviser services
	const removeSample = (id, size) => {
		_removeSample(id, size)
		if (samples.length <= 1) {
			locale === 'es-CO'
				? navigate('/muestra-gratis/')
				: navigate('/pide-tu-muestra/')
		}
	}
	const handleValidArrays = () => {
		const newCorrectArray = []
		const newWrongArray = []
		SamplesService.getSamplesSent(
			response => {
				if (response && response.data) {
					const responseData = response.data
					samples.forEach((sample, index) => {
						const isInCoverage =
							sample.customCoverage && sample.customCoverage?.length > 0
								? validateCoverage(sample.customCoverage)
								: true
						const filter = responseData.data.filter(data =>
							sample.size !== null && sample.size !== undefined
								? sample.size === data.size && data.id === sample.id
								: data.id === sample.id
						)
						if (filter.length === 0 && isInCoverage) {
							newCorrectArray.push(sample)
						} else {
							newWrongArray.push(sample)
							if (newWrongArray.length === 1) {
								setSamplesErrorModal(true)
							}
							// removeSample(sample.id, sample.size)
						}
					})
					setNewCorrectSamples(newCorrectArray)
					setNewWrongSamples(newWrongArray)
				}
			},
			error => {
				console.log(error)
			}
		)
	}
	return {
		samples,
		removeSample,
		department,
		setDepartment,
		city,
		setCity,
		kindOfStreet,
		setKindOfStreet,
		numberValue,
		setNumberValue,
		firstNumberValue,
		setFirstNumberValue,
		secondNumberValue,
		setSecondNumberValue,
		neighborhood,
		setNeighborhood,
		block,
		setBlock,
		aditionalInformation,
		setAditionalInformation,
		address,
		setAddress,
		addressDetail,
		setAddressDetail,
		addressARG,
		setAddressARG,
		heightARG, 
		setHeightARG,
		floorARG, 
		setFloorARG,
		departmentARG, 
		setDepartmentARG,
		zipCodeARG, 
		setZipCodeARG,
		aditionalInformationARG, 
		setAditionalInformationARG,
		isConfirmValid,
		loadedDirection,
		setLoadedDirection,
		haveMainDirection,
		haveSecondDirection,
		loadDirection,
		changeDirection,
		loadedOtherDirection,
		setLoadedOtherDirection,
		resetDirectons,
		sendSamples,
		isSampleSend,
		awaitSamples,
		sendSamplesAdviser,
		newCorrectSamples,
		newWrongSamples,
		handleValidArrays,
		response,
		setSamplesErrorModal,
		samplesErrorModal,
		sampleError,
		setSampleError,
		setSampleErrorMessage,
		sampleErrorMessage,
	}
}
