import { graphql, navigate } from 'gatsby'
import React, { useState } from 'react'
import ConfirmationSamples from '../components/molecules/confimationSamples/confirmation-samples'
import Layout from '../shared/components/organisms/layout/layout'
import useHomeLinkMode from '../shared/hooks/useHomeLinkMode'
import useLocaleMode from '../shared/hooks/useLocaleMode'
import useMobileMode from '../shared/hooks/useMobileMode'
import useSessionState from '../shared/hooks/useSessionState'
import SessionService from '../shared/services/session'
import SEO from '../shared/components/seo'

const HomeSectionConfirmationSample = props => {
	const { isMobile, currentPage } = useMobileMode()
	const { isLoggedUser } = useSessionState()
	const [swich, setSwich] = useState(false)
	const samples = SessionService.getSamples()
	const { locale } = useLocaleMode()
	const { homeLink } = useHomeLinkMode()

	React.useEffect(() => {
		if (!props.data.allContentfulTenaHome.nodes[0].tieneMuestras) {
			window.location.href = homeLink
		} else {
			if ((!isLoggedUser && swich) || (samples && samples.length <= 0)) {
				locale === 'es-CO'
					? navigate('/muestra-gratis/')
					: navigate('/pide-tu-muestra/')
			} else if (!swich) {
				setSwich(true)
			}
		}
	})

	const allProducts = []

	// add up all  all products of the categories
	props.data.allContentfulTenaSeccionProductos.nodes.forEach(element => {
		if (element.productos) {
			element.productos.forEach(producto => {
				const isSaved = allProducts.find(
					_producto => _producto.codigoDeProducto === producto.codigoDeProducto
				)
				if (!isSaved) {
					allProducts.push(producto)
				}
			})
		}
	})

	return (
		<Layout
			title='CONFIRMAR MUESTRA'
			isMobile={isMobile}
			currentPage={currentPage}
		>
			<SEO lang={locale} />
			<article>
				<ConfirmationSamples products={allProducts} />
			</article>
		</Layout>
	)
}

export default HomeSectionConfirmationSample

export const query = graphql`
	query GET_CONTENT_CONFIRMATION_FREE_SAMPLES($language: String!) {
		allContentfulTenaHome(filter: { node_locale: { eq: $language } }) {
			nodes {
				node_locale
				tieneTiendas
				tieneMuestras
			}
		}
		allContentfulTenaSeccionProductos(
			filter: { node_locale: { eq: $language } }
		) {
			nodes {
				productos {
					id
					codigoDeProducto
					metaTitulo
					metaDescripcion {
						metaDescripcion
					}
					tieneRetails
					retailsDelProducto {
						titulo
						logoDeRetail {
							fluid(maxWidth: 700, quality: 90) {
								src
								srcSet
								base64
								aspectRatio
								sizes
							}
							description
						}
						ctaRetail
						descripcionRetail {
							json
						}
						redireccionRetail
					}
					tituloCaracteristicas
					caracteristicas {
						descripcionDelSello {
							json
						}
						imagenDelSello {
							description
							file {
								url
							}
						}
					}
					id
					codigoDeProducto
					numeroDeGotas
					numeroDeGotasLlenas
					nombreProducto
					categorias {
						slug
					}
					nivelIncontinencia {
						content {
							content {
								value
							}
						}
					}
					imagenProductoPrevisualizacion {
						fluid(maxWidth: 800, quality: 100) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
						description
					}
					descripcionImagenProductoPrevisualizacion
					slug
					nombreProducto
					tipoDelUltimoDestacado
					reviewCount
					descripcionProducto {
						json
					}
					beneficiosImagen {
						description
						fluid(maxWidth: 800, quality: 90) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
					}
					imagenProductoCompleta {
						description
						fluid(maxWidth: 800, quality: 100) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
					}
					sellos {
						descripcionDelSello {
							json
						}
						imagenDelSello {
							description
							file {
								url
							}
						}
					}
				}
			}
		}
	}
`
