import React, { useEffect } from 'react'
import logoSample from '../../../shared/assets/images/icon-check.svg'
import useLocaleMode from '../../../shared/hooks/useLocaleMode'
import { pushGtagEvent } from '../../../shared/utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../shared/utils/userInfo'
import './sample-send.scss'

const SampleSend = ({newCorrectArray = []}) => {
	const { locale } = useLocaleMode()

	const userInfo = dataLayerUserInfo()
	const copyCountrySample = locale === 'es-AR' || locale === 'es-PY' || locale === 'es-UY' ? 'de 10 a 15' : locale === 'es-PE' ? 'en 10' : 'en 15'
console.log(locale);
	const gtag = {
		event: 'confirmacion_solicitud_muestra',
		params: {
			coupon: '',
			currency: locale,
			items: [],
			value: 0,
			flow_type: 'muestra gratis',
			user_data: userInfo.user_data ? userInfo.user_data : '',
			log_status: userInfo.log_status,
		}
	}
	const dataLayerMuestra = () => {
		newCorrectArray.forEach((sample) => {
			gtag.params.items.push({
				item_id: sample.id,
				item_name: sample.name,
				discount: 0,
				affiliation: 'Tena Online',
				item_brand: 'Tena',
				item_category: sample.categoria,
				item_variant: sample.variant,
				price: 0,
				currency: locale,
				quantity: 1,
				drip_degree: sample.drip_degree
			})
		});
		pushGtagEvent(gtag)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	})

	useEffect(() =>{
		if (newCorrectArray.length !== 0){
			dataLayerMuestra()
		}
	},[newCorrectArray])


	return (
		<div className='f-sample-send'>
			<img src={logoSample} alt='image check' />
			<h2>
				RECIBIMOS LA SOLICITUD DE TU MUESTRA{' '}
				{locale === 'es-CO' ? 'GRATIS' : ''}
			</h2>
			<p>
				La muestra {locale === 'es-CO' ? 'gratis' : ''} que elegiste llegará a
				tu casa {copyCountrySample} días hábiles, en un empaque discreto. Además, tus datos
				personales serán tratados con total confidencialidad de acuerdo a
				nuestra política de protección de datos.
				<br />
				<br />
				<b>
					Después de entregada la muestra te estaremos contactando para que
					compartas con nosotros una breve encuesta
				</b>
			</p>
		</div>
	)
}

export default SampleSend
