import React from 'react'
import LayoutModal from '../../../shared/components/organisms/layoutModal/layout-modal'
import InputFloat from '../../../shared/components/atoms/input-float/input-float'
import Alert from '../../../shared/components/atoms/alert/alert'
import './modalConfirmDirection.scss'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const ModalConfirmDirection = ({
	onClose,
	address,
	addressDetail,
	handleValidAction,
}) => {
	return (
		<LayoutModal onClose={onClose}>
			<section className='f-modal-direction'>
				<div className='f-card-modal-head'>
					<span className='f-card-modal-head-icon' onClick={onClose}>
						X
					</span>
				</div>
				<h3 className='f-modal-direction-title'>¿Tus datos están correctos?</h3>
				<div className='f-modal-direction-input'>
					<div className='f-form-input'>
						<InputFloat
							name={'address'}
							label={'Dirección y barrio'}
							value={address + (addressDetail ? ', ' + addressDetail : '')}
						/>
					</div>

					<Alert
						alertType='info'
						description='Te recomendamos revisar muy bien tu dirección y si tu barrio está dentro de las zonas de cobertura, para que tu muestra llegue sin problemas a la puerta de tu casa.'
					/>
				</div>

				<div className='f-modal-direction-field'>
					<button
						type='submit'
						className='f-modal-direction-field-button f-modal-direction-field-button-dark'
						onClick={onClose}
					>
						{convertText('MODIFICAR')}
					</button>

					<button
						type='submit'
						className='f-modal-direction-field-button'
						onClick={() => {
							handleValidAction()
							onClose()
						}}
					>
						{convertText('FINALIZAR')}
					</button>
				</div>
			</section>
		</LayoutModal>
	)
}

export default ModalConfirmDirection
