import React from 'react'
import { NOSCRIPT_PATTERN } from '../../../state/constants/appConstants'
import './input-float.scss'

const InputFloat = ({ label, value, setValue, name, className }) => {
	return (
		<div className={`f-input-float ${className}`}>
			<input
				id={name}
				name={name}
				value={value}
				onChange={event => {
					if (!NOSCRIPT_PATTERN.test(event.target.value)) {
						return false
					} else {
						setValue(event.target.value)
					}
				}}
				maxLength={300}
				defaultValue={value}
				type='text'
				className='f-input-float-input'
				required
			/>
			<label className='f-input-float-label' htmlFor={name}>
				{label}
			</label>
		</div>
	)
}

export default InputFloat
