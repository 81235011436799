import React from 'react'
import Button from '../../../shared/components/atoms/Button/Button'
import LayoutModal from '../../../shared/components/organisms/layoutModal/layout-modal'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'
import useLocaleMode from '../../../shared/hooks/useLocaleMode'
import ItemShop from '../../atoms/itemShop/item-shop'
import './modalSamplesConfirm.scss'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const ModalSamplesConfirm = ({ onClose, array, newCorrectArray }) => {
	const { homeLink } = useHomeLinkMode()
	const { locale } = useLocaleMode()

	const handleValidAction = () => {
		if (newCorrectArray.length > 0) {
			onClose(false)
		} else {
			window.location.href =
				homeLink + (locale === 'es-CO' ? 'muestra-gratis/' : 'pide-tu-muestra/')
		}
		localStorage.setItem('samples', JSON.stringify(newCorrectArray))
	}

	return (
		<LayoutModal onClose={onClose}>
			<section className='f-modal-samples-confirm'>
				<div className='f-modal-samples-confirm-content'>
					<h2 className='f-h2 f-modal-samples-title'>¡Lo sentimos!</h2>
					<p className='f-p f-modal-samples-description'>
						Hemos eliminado estos productos de tu solicitud de muestra porque ya
						los habías pedido
						<b>o no cuentas con cobertura en tu zona.</b>
					</p>
					<div className='f-samples-content'>
						{array.map((sample, index) => {
							return <ItemShop key={index} sample={sample} hideClose={true} />
						})}
					</div>
					<div className='f-modal-samples-footer'>
						<Button
							classname='f-button f-button--primary'
							text={convertText('CONTINUAR')}
							type='button'
							onClickAction={handleValidAction}
						/>
					</div>
				</div>
			</section>
		</LayoutModal>
	)
}

export default ModalSamplesConfirm
