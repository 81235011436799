import {
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
} from '@material-ui/core'
import InputMaterial from '../../../shared/components/atoms/input-material/input-material'
import InputMaterialWithCheck from "../../../shared/components/atoms/input-material-with-check/input-material-with-check"
import { useForm } from "react-hook-form"
import { graphql, navigate, useStaticQuery } from 'gatsby'
import React, {useEffect, useState} from 'react'
import iconBack from '../../../shared/assets/images/icon-back.svg'
import errorIcon from '../../../shared/assets/images/icon-advertencia.svg'
import logoSample from '../../../shared/assets/images/image-free-sample.png'
import InputFloat from '../../../shared/components/atoms/input-float/input-float'
import ButtonLoading from '../../../shared/components/molecules/buttonLoading/buttonLoading'
import useLocaleMode from '../../../shared/hooks/useLocaleMode'
import SessionService from '../../../shared/services/session'
import { isAdviser } from '../../../shared/state/utils/isAdviser'
import ItemShop from '../../atoms/itemShop/item-shop'
import SampleSend from '../../atoms/sample-send/sample-send'
import ModalSamplesConfirm from '../modalSamplesConfirm/modalSamplesConfirm'
import ModalConfirmDirection from '../modalConfirmDirection/modalConfirmDirection'
import './confirmation-samples.scss'
import useConfirmationSample from './useConfirmationSample'
import GenericModalAlert from '../../../shared/components/molecules/generic-modal-alert/generic-modal-alert'
import { pushDataLayerEvent, pushGtagEvent } from '../../../shared/utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../shared/utils/userInfo'
import useCrudFreeSample from '../../../shared/hooks/useCrudFreeSample'
import SamplesService from "../../../shared/services/samples"
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'
import { NO_SPECIAL_CHARACTERS_PATTERN, ADDRESS_PATTERN, NUMBER_PATTERN, FLOOR_PATTERN, DEPARTMENT_PATTERN, ADITIONAL_INFO_PATTERN } from '../../../shared/state/constants/appConstants'

const addDirection = 'Agregar otra direccion'
const toUseOtherDirection = 'Usar mi otra direccion'

const ConfirmationSamples = props => {
	const { locale } = useLocaleMode()
	const query = useStaticQuery(graphql`
		query COUNTRIES {
			allContentfulTenaPaises(
				sort: { fields: orden, order: ASC }
				filter: { node_locale: { eq: "es-CO" } }
			) {
				nodes {
					codigoDeDosLetras
					codigoDelPais
					lenguaje
					titulo
					dominio
					node_locale
					departamentos {
						codigo
						nombre
					}
					ciudades {
						codigo
						codigoDepartamento
						nombre
					}
					tipoDeCalle{
						idCalle
						name
					}
				}
			}
			allContentfulTenaRegistro(
				filter: { titulo: { eq: "Registro > Paso Cuatro" } }
			) {
				nodes {
					formularioInformacionPersonal {
						pais
						departamento
						ciudad
						node_locale
					}
				}
			}
		}
	`)

	const country = query.allContentfulTenaPaises.nodes.find(node =>
		locale.includes(node.codigoDeDosLetras)
	)
	const formInfo = query.allContentfulTenaRegistro.nodes.find(
		node => locale === node.formularioInformacionPersonal.node_locale
	)
	const {
		department,
		setDepartment,
		city,
		setCity,
		address,
		setAddress,
		addressARG,
		setAddressARG,
		heightARG, 
		setHeightARG,
		floorARG, 
		setFloorARG,
		departmentARG, 
		setDepartmentARG,
		zipCodeARG, 
		setZipCodeARG,
		aditionalInformationARG, 
		setAditionalInformationARG,
		kindOfStreet,
		setKindOfStreet,
		numberValue,
		setNumberValue,
		firstNumberValue,
		setFirstNumberValue,
		secondNumberValue,
		setSecondNumberValue,
		neighborhood,
		setNeighborhood,
		block,
		setBlock,
		aditionalInformation,
		setAditionalInformation,
		addressDetail,
		setAddressDetail,
		haveMainDirection,
		haveSecondDirection,
		loadDirection,
		loadedDirection,
		setLoadedDirection,
		changeDirection,
		loadedOtherDirection,
		resetDirectons,
		sendSamples,
		isSampleSend,
		awaitSamples,
		sendSamplesAdviser,
		newCorrectSamples,
		newWrongSamples,
		handleValidArrays,
		setSamplesErrorModal,
		samplesErrorModal,
		sampleError,
		setSampleError,
		sampleErrorMessage,
	} = useConfirmationSample(country, props.products)
	newCorrectSamples.length === 0 &&
		newWrongSamples.length === 0 &&
		handleValidArrays()
	haveMainDirection && !loadedDirection && loadDirection()

	const [samples, addSample, removeSample, response] = useCrudFreeSample()
	const [coverageCity, setCoverageCity] = useState()
	const isAR = locale === "es-AR"
	const isCO = locale === "es-CO"
	const userData = SessionService.getUserData()
	const [isNewAddres, setIsNewAddress] = useState(userData.nueva_direccion || false) 
	const [hideDivAddress, setHideDivAddress] = useState('')

	const hideAddress = () =>{
		return (isCO || isAR) && isNewAddres ? setHideDivAddress('f-hide--NewAddress') : setHideDivAddress('')
	} 
	const { register, errors, setError} = useForm()

	useEffect(() => {
	  setIsNewAddress(userData.nueva_direccion)
	  hideAddress()
	}, [userData])
	
	const handleBack = () =>
		locale === 'es-CO'
			? navigate('/muestra-gratis/')
			: navigate('/pide-tu-muestra/')

	const changeDepartment = value => {
		setDepartment(value)
		setCity(null)
	}

	const checkCities = async (value) => {
		if (value && value !== "") {
		  const res = await SamplesService.verifyCoverageSamples(value)
	
		  if (res.data.status !== 200) {
			setCoverageCity(false)
			return false
		  } else {
			setCoverageCity(true)
			return true
		  }
		}
	}
	const handleSendConfirm = () => {
		if (isAdviser(SessionService.getIdTokenData())) {
			sendSamplesAdviser()
		} else {
			sendSamples()
		}
	}

	const userInfo=dataLayerUserInfo()
	
	const gtag={
		event: 'add_shipping_info', 
		params:{
			flow_type: 'muestra gratis',
			items:[],
			value:0,
			user_data: userInfo.user_data? userInfo.user_data: '',
			log_status: userInfo.log_status,
		}}

	const noSpecialCharactersMessage = 'El campo contiene caracteres inválidos'
	const campoRequeridoMessage = "Este campos es requerido"

	const containsSpecialCharacters = value => {
		return !NO_SPECIAL_CHARACTERS_PATTERN.test(value)
	}

	const fieldFormatValidator = (field, value) => {
	
		switch (field) {
			case "address":
				return !ADDRESS_PATTERN.test(value);
				
			case "height":
				return !NUMBER_PATTERN.test(value)
			
			case "floor":
				return !FLOOR_PATTERN.test(value)
				
			case "department":
				return !DEPARTMENT_PATTERN.test(value)

			case "aditionalInfo":
				return !ADITIONAL_INFO_PATTERN.test(value)

			default:
				return !NO_SPECIAL_CHARACTERS_PATTERN.test(value)
		}
	}

	const addressValidation = value => {
		return !ADDRESS_PATTERN.test(value)
	}
	
		const handleFormatAddress = () => {
			let haveErrors = false

			if (kindOfStreet === '' || kindOfStreet === undefined) {
				setError('kindOfStreet', 'manual', 'Campo requerido')
				haveErrors = true
			} else if (containsSpecialCharacters(kindOfStreet)) {
				setError('kindOfStreet', 'manual', noSpecialCharactersMessage)
				haveErrors = true
			}

			if (neighborhood === '' || neighborhood === ' ') {
				setError('neighborhood', 'manual', 'Barrio requerido')
				haveErrors = true
			} else if (containsSpecialCharacters(neighborhood)) {
				setError('neighborhood', 'manual', noSpecialCharactersMessage)
				haveErrors = true
			}

			if (numberValue === '' || numberValue === ' ') {
				setError('numberStreet', 'manual', 'Campo requerido')
				haveErrors = true
			} else if (containsSpecialCharacters(numberValue)) {
				setError('numberStreet', 'manual', noSpecialCharactersMessage)
				haveErrors = true
			}

			if (
				(firstNumberValue === '# ' || firstNumberValue.trim() === '#') &&
				kindOfStreet !== 'Manzana'
			) {
				setError('firstNumberValue', 'manual', 'Campo requerido')
				haveErrors = true
			} else if (!/^#?[A-Za-z0-9À-ž\s]+$/.test(firstNumberValue)) {
				setError('firstNumberValue', 'manual', noSpecialCharactersMessage)
				haveErrors = true
			}

			if (
				(secondNumberValue === '- ' || secondNumberValue.trim() === '-') &&
				kindOfStreet !== 'Manzana'
			) {
				setError('secondaryNumber', 'manual', 'Campo requerido')
				haveErrors = true
			} else if (!/^-?[A-Za-z0-9À-ž\s]+$/.test(secondNumberValue)) {
				setError('secondaryNumber', 'manual', noSpecialCharactersMessage)
				haveErrors = true
			}

			if (block === '' || block === ' ') {
				setError('block', 'manual', 'Campo requerido')
				haveErrors = true
			} else if (containsSpecialCharacters(block)) {
				setError('block', 'manual', noSpecialCharactersMessage)
				haveErrors = true
			}

			if (containsSpecialCharacters(aditionalInformation)) {
				if (aditionalInformation !== '') {
					setError('aditionalInformation', 'manual', noSpecialCharactersMessage)
					haveErrors = true
				}
			}

			if (haveErrors) {
				return false
			} else {
				handleSetModalConfirm()
			}
			setAddress(
				`${kindOfStreet}?${numberValue}?${firstNumberValue}?${secondNumberValue}`
			)
			setAddressDetail(`${neighborhood}?${block}?${aditionalInformation}`)
		}

	const handleFormatAddressARG = () => {
		let haveErrors = false

		if (addressARG === '' || addressARG === undefined) {
			setError('addressARG', 'manual', campoRequeridoMessage)
			haveErrors = true
		} else if (fieldFormatValidator("address", addressARG)) {
			setError('addressARG', 'manual', noSpecialCharactersMessage)
			haveErrors = true
		} else if (addressARG.length < 5) {
			setError('addressARG', 'manual', "El campo debe tener mínimo 5 caracteres")
			haveErrors = true
		} else if (addressARG.length > 30) {
			setError('addressARG', 'manual', "El campo debe tener máximo 30 caracteres")
			haveErrors = true
		}

		if (heightARG === '' || heightARG === ' ') {
			setError('heightARG', 'manual', campoRequeridoMessage)
			haveErrors = true
		} else if (fieldFormatValidator("height", heightARG)) {
			setError('heightARG', 'manual', noSpecialCharactersMessage)
			haveErrors = true
		} else if (heightARG.length > 6) {
			setError('heightARG', 'manual', "El campo debe tener máximo 6 dígitos")
			haveErrors = true
		}

		if (floorARG === '' || floorARG === ' ') {
			setError('floorARG', 'manual', campoRequeridoMessage)
			haveErrors = true
		} else if (fieldFormatValidator("floor", floorARG)) {
			setError('floorARG', 'manual', noSpecialCharactersMessage)
			haveErrors = true
		} else if (floorARG.length > 12) {
			setError('floorARG', 'manual', "El campo debe tener máximo 12 caracteres")
			haveErrors = true
		}

		if (departmentARG === '' || departmentARG === ' ') {
			setError('departmentARG', 'manual', campoRequeridoMessage)
			haveErrors = true
		} else if (fieldFormatValidator("department", departmentARG)) {
			setError('departmentARG', 'manual', noSpecialCharactersMessage)
			haveErrors = true
		} else if (departmentARG.length > 5 && departmentARG !== "Sin departamento") {
			setError('departmentARG', 'manual', "El campo debe tener máximo 5 caracteres")
			haveErrors = true
		}

		if (zipCodeARG === '' || zipCodeARG === ' ') {
			setError('zipCodeARG', 'manual', campoRequeridoMessage)
			haveErrors = true
		} else if (fieldFormatValidator("zipCode", zipCodeARG)) {
			setError('zipCodeARG', 'manual', noSpecialCharactersMessage)
			haveErrors = true
		}

		if (fieldFormatValidator("aditionalInfo", aditionalInformationARG)) {
			setError('aditionalInformationARG', 'manual', noSpecialCharactersMessage)
			haveErrors = true
		} else if (aditionalInformationARG.length > 30) {
			setError('aditionalInformationARG', 'manual', "El campo debe tener máximo 30 caracteres")
			haveErrors = true
		}

		if (haveErrors) {
			return false
		} else {
			handleSetModalConfirm()
		}

		const floorARGPayload = floorARG === "Sin piso"? null : floorARG;
		const departmentARGPayload = departmentARG === "Sin departamento"? null : departmentARG;
		const aditionalInformationARGPayload = aditionalInformationARG === ""? null : aditionalInformationARG;

		setAddress(
			`${addressARG}?${heightARG}?${floorARGPayload}?${departmentARGPayload}`
		)
		setAddressDetail(`${zipCodeARG}?${aditionalInformationARGPayload}`)
	}

		const handleAlternativeAdress = () => {
			let haveErrors = false;

			if (address === '' || address === ' ') {
				setError('address', 'manual', 'Dirección requerida')
				haveErrors = true;
			} else if (addressValidation(address)) {
				setError('address', 'manual', noSpecialCharactersMessage)
				haveErrors = true;
			}

			if (addressDetail === '' || addressDetail === ' ') {
				setError('addressDetail', 'manual', 'Campo requerido')
				haveErrors = true;
			} else if (containsSpecialCharacters(addressDetail)) {
				setError('addressDetail', 'manual', noSpecialCharactersMessage)
				haveErrors = true;
			}
			if (haveErrors) {
				return;
			}
			handleSetModalConfirm();
		}
	
	const handleSetModalConfirm = () => {
		const nombres=[]
		const ids=[]
		newCorrectSamples.forEach((sample) =>{
			nombres.push(sample.name);
			ids.push(sample.id);
			gtag.params.items.push({item_id:sample.id,
				item_name: sample.name,
				discount: 0,
				affiliation: 'Tena Online',
				item_brand: 'Tena',
				item_category: sample.categoria,
				item_variant: sample.variant,
				price: 0,
				currency: locale,
				quantity: 1,
				drip_degree:sample.drip_degree
			})
		})   
		pushGtagEvent(gtag)
		pushDataLayerEvent({
			event: 'sample_form',
            action_type: 'CONFIRMAR MI SOLICITUD',
			step_name: 'Muestra gratis',
			step_number: 2,
            single_item_id: nombres.join(','),
            single_item_name: ids.join(',')
		})
		setModalConfirmation(true)
	}

	const [modalConfirmation, setModalConfirmation] = React.useState(false)

	const findKindOfStreet = (nameStreet) => {
		return country.tipoDeCalle.find(ele => ele.name === nameStreet)
	}

						
	const actionControl = !haveSecondDirection
		? resetDirectons
		: !loadedOtherDirection
		? changeDirection
		: () => setLoadedDirection(false)
	return isSampleSend ? (
		<SampleSend newCorrectArray={newCorrectSamples} />
	) : (
		<>
			<section className='f-samples-conf'>
				<div className='f-samples-conf-advice'>
					<img
						className='f-samples-conf-advice-img'
						src={logoSample}
						alt='Muestra'
					></img>
					<div className='f-samples-conf-advice-content'>
						<h3>MUESTRA {locale === 'es-CO' ? 'GRATIS' : ''}</h3>
						<p>
							Estos son los productos de tu muestra{' '}
							{locale === 'es-CO' ? 'gratis' : ''}
						</p>
					</div>
				</div>
				<div className='f-samples-conf-elements'>
					{newCorrectSamples.length > 0
						? newCorrectSamples.map((sample, key) => {
								return (
									<ItemShop
										key={key}
										sample={sample}
										actionClose={() =>{ 
											removeSample(sample.id, sample.size)
											const gtag ={
												event: 'remove_from_cart',
												params:{
													items: [{
														item_id: sample.id,
														item_name: sample.name,
														discount: 0,
														affiliation: 'Tena Online',
														item_brand: 'Tena',
														item_category: sample.categoria,
														item_variant: sample.variant,
														price: 0,
														currency: locale,
														quantity: 1,
													}],
													value: 0,
													option: 'Tena Muestras Gratis',
													flow_type: 'Muestra Gratis',
													drip_degree: sample.drip_degree,
													user_data: userInfo.user_data? userInfo.user_data: '',
													log_status: userInfo.log_status,
												}								
											}
											pushGtagEvent(gtag)

											if (samples.length <= 1) {
												locale === 'es-CO'
													? navigate('/muestra-gratis/')
													: navigate('/pide-tu-muestra/')
											}else{
												window.location.reload()
											}
										}}
									/>
								)
						  })
						: null}
				</div>
				<div className='f-samples-conf-wrapper'>
					<div className='f-samples-conf-form'>
						<h3 className='f-samples-conf-form-title'>
							Esta es la dirección a la cual enviaremos la muestra{' '}
							{locale === 'es-CO' ? 'gratis' : ''}
						</h3>
						<p className='f-samples-conf-form-subtitle'>
							Puedes ingresar o actualizar la dirección con la cual te
							registraste en nuestro sitio Web o agregar una nueva dirección.
						</p>
							<div className={`f-samples-conf-form-fields ${hideDivAddress} ${!isCO && !isAR ? 'f-hide--NewAddress' : ''} `}>
								<InputFloat
									name={'address'}
									label={'Dirección y barrio'}
									value={(address + (addressDetail ? ', ' + addressDetail : '')).replaceAll('?',' ')}
									className={'f-samples-conf-form-address-input'}
								/>
							</div>
						<div className={`f-samples-conf-form-fields ${isAR ? "f-form-group" : ""}`}>
							{country && formInfo && (
								<>
									<FormControl
										variant='outlined'
										className={`f-form-input f-form-input-select f-form-input-small ${
											locale === 'es-PR'
												? 'f-select-extended'
												: 'f-select-normal'
										}`}
									>
										<InputLabel htmlFor='country'>¿Cuál es tu país?</InputLabel>
										<Select
											name='country'
											value={country.codigoDeDosLetras || ''}
											input={
												<OutlinedInput
													name='country'
													id='outlined-country-select'
												/>
											}
											disabled={true}
										>
											<MenuItem value={country.codigoDeDosLetras}>
												{country.titulo}
											</MenuItem>
										</Select>
									</FormControl>

									<FormControl
										variant='outlined'
										className={`f-form-input f-form-input-select f-form-input-small ${
											locale === 'es-PR'
												? 'f-select-extended'
												: 'f-select-normal'
										}`}
									>
										<InputLabel
											shrink={true}
											filled={true}
											htmlFor={'department'}
										>
											{formInfo.formularioInformacionPersonal.departamento}
										</InputLabel>
										<Select
											input={
												<OutlinedInput
													name={
														formInfo.formularioInformacionPersonal.departamento
													}
													id='outlined-department-select'
												/>
											}
											autoFocus
											name='department'
											readOnly={!changeDepartment}
											defaultValue={department || ''}
											value={department}
											onChange={
												changeDepartment
													? evt => changeDepartment(evt.target.value)
													: () => null
											}
										>
											{country.departamentos.map(dep => (
												<MenuItem key={dep.codigo} value={dep.codigo}>
													{dep.nombre}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									{locale === 'es-PR' ? null : (
										<FormControl
											variant='outlined'
											className={
												'f-form-input f-form-input-select f-form-input-small f-select-normal'
											}
										>
											<InputLabel
												shrink={true}
												filled={true}
												htmlFor={'department'}
											>
												{formInfo.formularioInformacionPersonal.ciudad}
											</InputLabel>
											<Select
												input={
													<OutlinedInput
														name={formInfo.formularioInformacionPersonal.ciudad}
														id='outlined-department-select'
													/>
												}
												name='city'
												readOnly={!setCity}
												value={city || ''}
												onChange={ (evt) =>{
													if (isAR){
													const isCoverageCity = async () => await checkCities(evt.target.value)
													setCoverageCity(isCoverageCity())
													}
													if (setCity){
														 setCity(evt.target.value) 
														} else {
														return null 
													}
												}
												}
											>
												{country.ciudades
													.filter(
														(city => city.codigoDepartamento === department) ||
															[]
													)
													.map(city => (
														<MenuItem key={city.codigo} value={city.codigo}>
															{city.nombre}
														</MenuItem>
													))}
											</Select>
										</FormControl>
									)}
								</>
							)}
						</div>
						{(isAR === true && coverageCity === false) && <p className="f-alert-description">
							{" Por el momento no tenemos cobertura en esta área para muestras gratis."}
						</p>}
						{isCO ?
							<>
								<div className='f-samples-conf-form-fields'>
									<FormControl
										variant='outlined'
										className='f-form-input f-form-input-select f-form-input-small f-select-address'
									>
										<InputLabel shrink={true}
											filled={true}
											htmlFor='kindOfStreet'>
											{'Tipo de Calle'}
										</InputLabel>
										<Select
											input={
												<OutlinedInput
													name={'kindOfStreet'}
													id='outlined-kindOfStreet-select'
												/>
											}
											error={errors.kindOfStreet}
											value={findKindOfStreet(kindOfStreet) || ''}
											name='kindOfStreet'
											displayEmpty
											onChange={(event) => {
												setKindOfStreet(event.target.value.name)
												delete errors.kindOfStreet
												if(event.target.value.name === 'Manzana'){
													delete errors.secondaryNumber
													delete errors.firstNumberValue
												}
											}}
										>
											{country.tipoDeCalle.map(
												kindOfStreet => (
													<MenuItem key={'kindOfStreet' + kindOfStreet.idCalle} value={kindOfStreet}>
														{kindOfStreet.name}
													</MenuItem>
												)
											)}
										</Select>
									</FormControl>
									<InputMaterial
										change={(e) => {
											if (e.target.value.length <= 20) {
												setNumberValue(e.target.value)
												delete errors.numberStreet
											}
										}}
										errors={errors}
										name={'numberStreet'}
										type='text'
										autoComplete={false}
										classes='f-input-float-address '
										id='f-user-numberStreet'
										label={`${kindOfStreet} *`}
										value={numberValue}
										register={register({
											required: {
												value: true,
												message: 'Campo obligatorio.',
											},
										})}
									/>
									<InputMaterial
										name="firstNumberValue"
										classes='f-input-material-address--primary f-input-float-address'
										register={register(kindOfStreet !== 'Manzana' ? {
											required: { value: true, message: "El campo es requerido." },
											minLength: {
												value: 3,
												message: 'El campo es requerido.',
											},
										}:{})}
										label='Numero *'
										change={e => {
											if (e.target.value.length <= 2) {
												setFirstNumberValue("# ")
												delete errors.firstNumberValue
											} else if (e.target.value.length <= 20) {
												setFirstNumberValue(e.target.value)
												delete errors.firstNumberValue
											}
										}}
										value={firstNumberValue || '# '}
										errors={errors}
									/>
									<InputMaterial
										name="secondaryNumber"
										classes='f-input-material-address--primary f-input-float-address'
										register={register({
											required: {
												value: true,
												message: 'Campo requerido',
											}})}
										change={e => {
											if (e.target.value.length <= 2) {
												setSecondNumberValue("- ")
												delete errors.secondaryNumber
											} else if (e.target.value.length <= 20) {
												setSecondNumberValue(e.target.value)
												delete errors.secondaryNumber
											}
										}}
										value={secondNumberValue || '- '}
										errors={errors}
									/>
									<InputMaterial
										change={(e) => {
											if(e.target.value.length <= 120){
												setNeighborhood(e.target.value)
												delete errors.neighborhood}
										}}
										errors={errors}
										name={'neighborhood'}
										type='text'
										autoComplete={false}
										classes='f-form-input f-form-input-neighborhood f-input-float-neighborhood '
										id='f-user-neighborhood'
										label={'Barrio'}
										value={neighborhood}
										register={register({
											required: {
												value: true,
												message: 'Campo obligatorio.',
											},
										})}
									/>
								</div>
								<div className='f-samples-conf-form-fields'>
									<InputMaterial
										change={(e) => {
											if(e.target.value.length <= 50){
												setBlock(e.target.value)
												delete errors.block}
										}}
										errors={errors}
										name={'block'}
										type='text'
										autoComplete={false}
										classes='f-form-input  f-input-float'
										id='f-user-secondNumber'
										label={'Bloque o torre / Apartamento'}
										value={block}
										register={register({
											required: {
												value: true,
												message: 'Campo obligatorio.',
											},
										})}
									/>
									<InputMaterial
										change={(e) => {
											if(e.target.value.length <= 50){
												setAditionalInformation(e.target.value)
												delete errors.aditionalInformation
											}
										}}
										errors={errors}
										name={'aditionalInformation'}
										type='text'
										autoComplete={false}
										classes='f-form-input  f-input-float'
										id='f-user-aditionalInformation'
										label={'Referencias adicionales'}
										value={aditionalInformation}
										register={register()}
									/>
								</div>
							</>
						: isAR ?
							<>
								<div className="f-form-group">
									<InputMaterial
										errors={errors}
										name="addressARG"
										autoComplete={false}
										classes='f-input-material-address--direccion'
										label={"Dirección *"}
										value={addressARG}
										change={(e) => {
											setAddressARG(e.target.value)
											delete errors.addressARG
										}}
									/>
									<InputMaterial
										name="heightARG"
										autoComplete={false}
										classes='f-input-material-address--height'
										value={heightARG}
										change={(e) => {
											setHeightARG(e.target.value)
											delete errors.heightARG
										}}
										label={"Altura *"}
										errors={errors}
									/>
								</div>
								<div className="f-form-group">
									<InputMaterialWithCheck
										name="floorARG"
										id="floorARG"
										text='piso'
										classes='f-input-material-address--floor'
										autoComplete={false}
										value={floorARG}
										whatFormIs="muestras-gratis"
										setValue={setFloorARG}
										change={(e) => {
											setFloorARG(e.target.value)
											delete errors.floorARG
										}}
										label={"Piso *"}
										errors={errors}
										showMessageError={floorARG !== "Sin piso"}
									/>
									<InputMaterialWithCheck
										name="departmentARG"
										classes='f-input-material-address--department'
										id="departmentARG"
										text='departamento'
										autoComplete={false}
										value={departmentARG}
										whatFormIs="muestras-gratis"
										setValue={setDepartmentARG}
										change={(e) => {
											setDepartmentARG(e.target.value)
											delete errors.departmentARG
										}}
										label={"Departamento *"}
										errors={errors}
										showMessageError={departmentARG !== "Sin departamento"}
									/>
								</div>
								<div className="f-form-group">
									<InputMaterial
										name="zipCodeARG"
										autoComplete={false}
										classes='f-input-material-address--zip-code'
										value={zipCodeARG}
										change={(e) => {
											if (e.target.value.length <= 8) {
												setZipCodeARG(e.target.value)
												delete errors.zipCodeARG
											}
										}}
										label={"Código postal *"}
										errors={errors}
									/>
									<InputMaterial
										name="aditionalInformationARG"
										autoComplete={false}
										classes='f-input-material-address--aditional-info'
										value={aditionalInformationARG}
										change={(e) => {
											setAditionalInformationARG(e.target.value)
											delete errors.aditionalInformationARG
										}}
										label={"Información adicional"}
										errors={errors}
									/>
								</div>
							</>
						:
							<>
								<div className='f-samples-conf-form-fields'>
									<InputMaterial
										change={(e) => {
										if (e.target.value.length <= 35) {
											setAddress(e.target.value)
											delete errors.address
										}
										}}
										errors={errors}
										name={'address'}
										type='text'
										autoComplete={false}
										classes='f-input-float'
										label={'¿Cuál es tu dirección?'}
										value={address}
									/>
								</div>
								<div className='f-samples-conf-form-fields'>
									<InputMaterial
										change={(e) => {
											if (e.target.value.length <= 35) {
												setAddressDetail(e.target.value)
												delete errors.addressDetail
											}
										}}
										errors={errors}
										name={'addressDetail'}
										type='text'
										autoComplete={false}
										classes='f-input-float'
										label={'Detalle dirección'}
										value={addressDetail}
									/>
								</div>
							</>
						}
					</div>
					<div className='f-samples-conf-buttons'>
						<div className='f-samples-conf-buttons-container'>
							<button
								className='f-samples-conf-buttons-container-back'
								onClick={handleBack}
							>
								<img src={iconBack} alt='Icono de atrás'></img>
							</button>
							<ButtonLoading
								isShowingLoader={awaitSamples}
								bgClass='primary'
								button={
									<button
										onClick={()=>{
											if(isCO){
												handleFormatAddress()
											}else if(isAR){
												handleFormatAddressARG()
											}
											else{
												handleAlternativeAdress()
											}}}
										className='f-samples-conf-buttons-container-next'
									>
										{convertText('CONFIRMAR MI SOLICITUD')}
									</button>
								}
							/>
						</div>
						{haveMainDirection && (
							<div onClick={actionControl} className='f-samples-conf-reference'>
								{convertText(!haveSecondDirection
									? addDirection
									: haveSecondDirection && toUseOtherDirection)}
							</div>
						)}
					</div>
				</div>
			</section>
			{samplesErrorModal && (
				<ModalSamplesConfirm
					newCorrectArray={newCorrectSamples}
					array={newWrongSamples}
					onClose={setSamplesErrorModal}
				/>
			)}
			{sampleError && (
				<GenericModalAlert
					icon={errorIcon}
					title='Lo sentimos'
					description={sampleErrorMessage}
					handleAction={setSampleError}
					type='primary'
				/>
			)}
			{modalConfirmation && (
				<ModalConfirmDirection
					onClose={() => setModalConfirmation(false)}
					address={isAR? address.replaceAll('?', ' ').replaceAll("null", "").trim() : address.replaceAll('?', ' ')}
					addressDetail={isAR? addressDetail.replaceAll('?', ' ').replaceAll("null", "").trim() : addressDetail.replaceAll('?', ' ')}
					handleValidAction={handleSendConfirm}
				/>
			)}
		</>
	)
}

export default ConfirmationSamples
