import React from 'react'
import './item-shop.scss'

const ItemShop = ({ sample, actionClose, hideClose }) => {
	return (
		<div className='f-item-shop'>
			{(!hideClose && (
				<span className='f-item-shop-close' onClick={actionClose}>
					x
				</span>
			)) ||
				null}
			{sample.size && (
				<span className={`f-item-shop-size size-${sample.size.toLowerCase()}`}>
					{sample.size}
				</span>
			)}
			<div
				className='f-item-shop-header'
				style={{ backgroundImage: `url(${sample.img})` }}
			/>
			<h4 className='f-item-shop-title'>{sample.name}</h4>
		</div>
	)
}

export default ItemShop
