import React from 'react'
import { localStorage, sessionStorage } from './../state/utils/storage'

export default function useCoverageState(citys) {
	const [isCoverage, setCoverage] = React.useState(true)
	const [isAddress, setIsAddress] = React.useState(false)
	const userData = localStorage.getItem('userData')
		? localStorage.getItem('userData')
		: sessionStorage.getItem('userData')

	const validateCoverage = (customCities = citys) => {
		if (userData) {
			const userCity = userData.mainCity ? userData.mainCity.toString() : ''
			const response =
				customCities.filter(
					city =>
						city.codigo.toString() === userCity && city.coverage === 'true'
				).length > 0
			setCoverage(response)
			return response
		}
	}

	const validateAddress = () => {
		if (userData) {
			const userCity = !!userData.mainCity
			setIsAddress(userCity)
			return userCity
		}
	}

	return [isCoverage, validateCoverage, isAddress, validateAddress]
}
